import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithClassName } from '@Components/helper';
import Logo, { LogoVariant } from '@Components/molecules/Logo/Logo';
import { TextSize } from '@Helpers/types/text';
import { Root, StyledText } from './FooterMessage.styles';
const FooterMessage = (props: PropsWithClassName): React.ReactElement => {
  const {
    t
  } = useTranslation('global');
  return <Root className={props.className} data-testid={props.dataTestId ?? 'footer-message'} data-sentry-element="Root" data-sentry-component="FooterMessage" data-sentry-source-file="FooterMessage.tsx">
      <Logo variant={LogoVariant.Logo} height={32} data-sentry-element="Logo" data-sentry-source-file="FooterMessage.tsx" />
      <StyledText size={TextSize.Xs} data-sentry-element="StyledText" data-sentry-source-file="FooterMessage.tsx">{t('footer.message')}</StyledText>
    </Root>;
};
export default FooterMessage;