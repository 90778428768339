import { CookieKey } from '@Components/helper/cookie';

export type Cookie = {
  key: string;
  value: string;
  delete: boolean;
  sameSite?: 'lax' | 'strict';
  expires?: number;
};

export const setCookieAuthorization = async (authorization: string) => {
  await setCookies([
    {
      key: CookieKey.Authorization,
      value: authorization,
      delete: false,
    },
  ]);
};

export const setCookiesAuthorization = async (
  authorization: string,
  refreshToken?: string,
  refreshTokenExpiration?: number
) => {
  const cookies: Cookie[] = [
    {
      key: CookieKey.Authorization,
      value: authorization,
      sameSite: 'lax',
      delete: false,
    },
  ];
  if (refreshToken) {
    cookies.push({
      key: CookieKey.RefreshToken,
      value: refreshToken,
      delete: false,
      expires: refreshTokenExpiration,
    });
  }
  await setCookies(cookies);
};

export const deleteCookieAuthorization = async () => {
  await setCookies([
    {
      key: CookieKey.Authorization,
      value: '',
      delete: true,
    },
  ]);
};

export const deleteCookieRefreshToken = async () => {
  await setCookies([
    {
      key: CookieKey.RefreshToken,
      value: '',
      delete: true,
    },
  ]);
};

export const setCookieUser = async (user: string) => {
  await setCookies([
    {
      key: CookieKey.User,
      value: user,
      delete: false,
    },
  ]);
};

export const deleteCookieUser = async () => {
  await setCookies([
    {
      key: CookieKey.User,
      value: '',
      delete: true,
    },
  ]);
};

export const setCookieTranslation = async (language: string) => {
  await setCookies([
    {
      key: CookieKey.Translation,
      value: language,
      delete: false,
    },
  ]);
};

export const setCookieTheme = async (themeId: string) => {
  await setCookies([
    {
      key: CookieKey.Theme,
      value: themeId,
      delete: false,
    },
  ]);
};

const setCookies = async (cookies: Cookie[]) => {
  await fetch('/api/cookie/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ cookies: cookies }),
  });
};
