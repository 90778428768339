import styled, { css } from 'styled-components';

import { Icon } from '@Components/atoms/Icon/Icon';
import Link from '@Components/atoms/Link';
import Row from '@Components/atoms/Row';
import Text from '@Components/atoms/Text';
import UserImage from '@Components/atoms/UserImage';
import { fadeIn } from '@Components/helper/animation';
import { adjustAlpha } from '@Components/helper/styleHelper';
import Logo from '@Components/molecules/Logo/Logo';

export const headerHeight = '62px';

export const Root = styled.header`
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 110;

  background-color: ${(props) => props.theme.palette.background.header};
  transition: background-color ${(props) => props.theme.transition.theme};
`;

export const HeaderRow = styled(Row)`
  height: ${headerHeight};
`;

export const Content = styled(Row)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    gap: 1.5rem;
  }
`;

export const VisitorsOnline = styled.div`
  ${fadeIn(1)};

  // fix mobile navi is too big with visitor count todo: remove after mobile navi is fixed
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const VisitorCounter = styled(Text)`
  min-width: 2.5rem;
`;

export const TabletDesktopLogo = styled(Logo)`
  display: flex;
  align-self: center;

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const MobileLogo = styled(Logo)`
  display: flex;
  align-self: center;

  @media (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const LanguageIcon = styled(Icon)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const StyledRow = styled(Row)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const UserRow = styled(Row)`
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      div {
        transition: border ${(props) => props.theme.transition.theme};
        border-color: ${(props) => props.theme.palette.text.themePrimary};
      }

      p {
        color: ${(props) => props.theme.palette.text.themePrimary};
      }
    }
  }
`;

export const Username = styled(Text)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const UserImageWrapper = styled.div`
  position: relative;
`;

export const StyledUserImage = styled(UserImage)<{ $isOpen: boolean }>`
  border: 1px solid
    ${(props) =>
      props.$isOpen ? props.theme.palette.text.themePrimary : adjustAlpha(props.theme.palette.text.header, 0.3)};
  border-radius: 50%;
  transition: border ${(props) => props.theme.transition.theme};
`;

export const Counter = styled.div`
  position: absolute;
  bottom: -5px;
  right: -10px;
  background-color: ${(props) => props.theme.palette.badge.primary.background};
  color: ${(props) => props.theme.palette.badge.primary.text};
  padding: 3px 7px;
  border-radius: 60%;
  font-size: 10px;
  font-weight: ${(props) => props.theme.font.text.semibold.small.fontWeight};
  font-family: ${(props) => props.theme.font.primaryFamily};
`;

export const Chevron = styled.span<{ $isActive: boolean }>`
  width: 12px;
  height: 12px;
  position: relative;
  transition: transform 0.4s ease;
  transform: rotate(0);

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 2px;
    height: 10px;
    background-color: transparent;
    border-bottom: 12px solid ${(props) => props.theme.palette.text.header};
    transition: 0.4s ease;
    top: -1px;
    left: 0;
  }

  &:before {
    transform: rotate(-135deg);
  }

  &:after {
    transform: rotate(135deg);
  }

  ${(props) =>
    props.$isActive &&
    css`
      transform: translate(0, -6px);

      &:before,
      &:after {
        border-bottom: 12px solid ${(props) => props.theme.palette.text.themePrimary};
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    `}
`;

export const GameModes = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  &:hover {
    p {
      color: ${(props) => props.theme.palette.text.themePrimary};
    }

    ${Chevron} {
      &:before,
      &:after {
        border-bottom: 12px solid ${(props) => props.theme.palette.text.themePrimary};
      }
    }
  }

  @media (max-width: ${(props) => props.theme.device.desktop}) {
    display: none;
  }
`;

export const GameModesText = styled(Text)`
  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;

export const LoginSignupLink = styled(Link)`
  color: ${(props) => props.theme.palette.text.header};

  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;
