import React from 'react';
import Col from '@Components/atoms/Col';
import Container from '@Components/atoms/Container';
import Copyright from '@Components/atoms/Copyright/Copyright';
import FooterMessage from '@Components/atoms/FooterMessage/FooterMessage';
import Row from '@Components/atoms/Row';
import SocialIcons from '@Components/atoms/SocialIcons/SocialIcons';
import { PropsWithClassName } from '@Components/helper';
import NavigationFooter from '@Components/molecules/NavigationFooter/NavigationFooter';
import { Root, StyledDivider } from './Footer.styles';
const Footer = (props: PropsWithClassName): React.ReactElement => {
  return <Root className={props.className} data-testid={props.dataTestId ?? 'footer-root'} data-sentry-element="Root" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Row data-sentry-element="Row" data-sentry-source-file="Footer.tsx">
          <Col xs={12} sm={12} md={3} lg={3} data-sentry-element="Col" data-sentry-source-file="Footer.tsx">
            <FooterMessage data-sentry-element="FooterMessage" data-sentry-source-file="Footer.tsx" />
            <SocialIcons data-sentry-element="SocialIcons" data-sentry-source-file="Footer.tsx" />
          </Col>
          <Col xs={12} sm={12} md={9} lg={9} data-sentry-element="Col" data-sentry-source-file="Footer.tsx">
            <NavigationFooter data-sentry-element="NavigationFooter" data-sentry-source-file="Footer.tsx" />
          </Col>
        </Row>
        <StyledDivider data-sentry-element="StyledDivider" data-sentry-source-file="Footer.tsx" />
        <Copyright data-sentry-element="Copyright" data-sentry-source-file="Footer.tsx" />
      </Container>
    </Root>;
};
export default Footer;