import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';

export type LocalStorageRefreshToken = {
  refreshToken: string | null;
  refreshTokenExpiration: number | null;
};

enum LocalStorageKeys {
  User = 'User',
  Authorization = 'Authorization',
  RefreshToken = 'RefreshToken',
  RefreshTokenExpiration = 'RefreshTokenExpiration',
  Settings = 'Settings',
  CasualMatchReconnectionToken = 'CasualMatchReconnectionToken',
  PasswordRecoveryDate = 'PasswordRecoveryDate',
  RegistrationDate = 'RegistrationDate',
  GlobalColyseusRoomClientId = 'GlobalColyseusRoomClientId',
}

export const setMultiplayerReconnectionToken = (reconnectionToken: string) => {
  setToLS(LocalStorageKeys.CasualMatchReconnectionToken, reconnectionToken);
};

export const getMultiplayerReconnectionToken = () => {
  return getFromLS(LocalStorageKeys.CasualMatchReconnectionToken);
};

export const deleteMultiplayerReconnectionToken = () => {
  deleteFromLS(LocalStorageKeys.CasualMatchReconnectionToken);
};

export const getLocalStorageUser = (): LocalStorageUserModel | null => {
  const lsUser = getFromLS(LocalStorageKeys.User);
  const lsAuth = getLocalStorageAuthorization();
  if (!lsUser || !lsAuth) {
    return null;
  }

  return LocalStorageUserModel.hydrateFromJson(lsUser, lsAuth);
};

export const setLocalStorageUser = (localStorageUser: string) => {
  setToLS(LocalStorageKeys.User, localStorageUser);
};

export const deleteLocalStorageUser = () => {
  deleteFromLS(LocalStorageKeys.User);
};

export const getLocalStorageAuthorization = (): string | null => {
  return getFromLS(LocalStorageKeys.Authorization);
};

export const setLocalStorageAuthorization = (authorization: string) => {
  setToLS(LocalStorageKeys.Authorization, authorization);
};

export const getLocalStorageRefreshToken = (): LocalStorageRefreshToken => {
  const exp = getFromLS(LocalStorageKeys.RefreshTokenExpiration);
  return {
    refreshToken: getFromLS(LocalStorageKeys.RefreshToken),
    refreshTokenExpiration: typeof exp === 'string' ? parseInt(exp) : exp,
  };
};

export const setLocalStorageRefreshToken = (refreshToken: string, refreshTokenExpiration: number) => {
  setToLS(LocalStorageKeys.RefreshToken, refreshToken);
  setToLS(LocalStorageKeys.RefreshTokenExpiration, refreshTokenExpiration.toString());
};

export const deleteLocalStorageRefreshToken = () => {
  deleteFromLS(LocalStorageKeys.RefreshToken);
  deleteFromLS(LocalStorageKeys.RefreshTokenExpiration);
};

export const deleteLocalStorageAuthorization = () => {
  deleteFromLS(LocalStorageKeys.Authorization);
};

export const setPasswordRecoveryDate = (date: Date) => {
  setToLS(LocalStorageKeys.PasswordRecoveryDate, date.toISOString());
};

export const getPasswordRecoveryDate = (): Date | null => {
  const date = getFromLS(LocalStorageKeys.PasswordRecoveryDate);
  if (!date) {
    return null;
  }
  return new Date(date);
};

export const deletePasswordRecoveryDate = () => {
  deleteFromLS(LocalStorageKeys.PasswordRecoveryDate);
};

export const setRegistrationDate = (date: Date) => {
  setToLS(LocalStorageKeys.RegistrationDate, date.toISOString());
};

export const getRegistrationDate = (): Date | null => {
  const date = getFromLS(LocalStorageKeys.RegistrationDate);
  if (!date) {
    return null;
  }
  return new Date(date);
};

export const deleteRegistrationDate = () => {
  deleteFromLS(LocalStorageKeys.RegistrationDate);
};

export const setGlobalColyseusRoomClientId = (id: string) => {
  setToLS(LocalStorageKeys.GlobalColyseusRoomClientId, id);
};

export const getGlobalColyseusRoomClientId = () => {
  return getFromLS(LocalStorageKeys.GlobalColyseusRoomClientId);
};

const setToLS = (key: string, value: string): void => {
  assertWindowIsAvailable(key);

  window.localStorage.setItem(key, value);
};

const getFromLS = (key: string): string | null => {
  assertWindowIsAvailable(key);

  return window.localStorage.getItem(key);
};

const deleteFromLS = (key: string) => {
  assertWindowIsAvailable(key);

  window.localStorage.removeItem(key);
};

const assertWindowIsAvailable = (key: string): void => {
  // should never happen. At this point window should always be loaded
  if (typeof window === 'undefined') {
    throw new Error(
      `Unexpected Error while handling local storage with key [${key}]. 
      [window] is not accessible`
    );
  }
};
